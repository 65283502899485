import { Button, Section } from "@/components";
import { DataContext } from "@/pages/index";
import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import React, { useContext } from "react";

import blob2 from "./blob2.svg";
import yoyo from "./yoyo.png";

const Competencies = styled.div`
  *:nth-of-type(odd) {
    padding-left: 5rem;
  }

  *:nth-of-type(even) {
    padding-right: 5rem;
  }
`;

const Container = styled.div`
  background-image: url(${blob2});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const WhyMomentium = () => {
  const doc = useContext(DataContext);
  const node = doc.body[1];

  return (
    <Section id="our-services" classNames="uk-text-center uk-position-relative">
      <Container
        className="uk-container uk-container-small ball-illo"
        style={{
          backgroundImage: "url(" + yoyo + ")",
          backgroundSize: "contain",
          backgroundPosition: "top right",
          maxWidth: "100%"
        }}
        data-uk-parallax="bgy: 20, -70"
      >
        <h2>Why Momentium?</h2>

        <div className="uk-width-1-2@s uk-margin-auto uk-margin-large-bottom">
          {RichText.render(node.primary.text)}
        </div>

        <Competencies className="uk-flex uk-flex-column">
          {node.fields.map(({ competency }, index) => (
            <h3 key={index.toString()} className="uk-margin-remove">
              {RichText.asText(competency)}
            </h3>
          ))}
        </Competencies>

        <Button.CTA color="blue" to="/who-we-are">
          Learn More
        </Button.CTA>
        <br />
      </Container>
    </Section>
  );
};

export default WhyMomentium;
