import { Blob, BoostYourBusiness } from "@/components/sections";
import {
  Hero,
  OurServices,
  PastWork,
  WhyMomentium
} from "@/components/sections/Homepage";
import { OurServicesFragment, WhyMomentiumFragment } from "@/fragments";
import { graphql } from "gatsby";
import React from "react";

export const DataContext = React.createContext({});

export const query = graphql`
  query {
    prismic {
      allProjects(sortBy: meta_firstPublicationDate_DESC, first: 2) {
        edges {
          node {
            title
            thumbnail
          }
        }
      }
      allHomepages {
        edges {
          node {
            title
            content
            footer
            description
            _linkType
            body {
              ... on PRISMIC_HomepageBodyPast_work {
                primary {
                  past_description
                }
              }

              ... on PRISMIC_HomepageBodyOur_services {
                type
                label
                fields {
                  illustration
                  name
                  caption
                }
                primary {
                  text
                }
              }

              ... on PRISMIC_HomepageBodyWhy_momentium {
                type
                label
                primary {
                  text
                }
                fields {
                  competency
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <DataContext.Provider value={data.prismic.allHomepages.edges[0].node}>
    <div uk-scrollspy="target: > section; cls: uk-animation-fade; offset-top: 80; delay: 200;">
      <Hero />
      <OurServices />
      <WhyMomentium />
      <PastWork />
      <BoostYourBusiness />
      <Blob />
    </div>
  </DataContext.Provider>
);

IndexPage.query = query;

export default IndexPage;
