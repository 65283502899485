import { Button, ScrollTo, Section } from "@/components";
import { DataContext } from "@/pages/index";
import { RichText } from "prismic-reactjs";
import React, { useContext } from "react";

import drawing from "./drawing.svg";

const Hero = () => {
  const doc = useContext(DataContext);
  if (!doc) return null;

  return (
    <Section
      background={{
        src: drawing,
        position: "top right",
        size: "auto 95%"
      }}
    >
      <div className="uk-container uk-container-small">
        <div className="uk-width-1-2@s">
          <RichText render={doc.title} />
          <h2 className="uk-h3">{RichText.asText(doc.content)}</h2>
          <Button color="orange" to="/contact-us">
            Let's Talk
          </Button>
        </div>
      </div>
      <ScrollTo href="#our-services" />
      <br />
    </Section>
  );
};

export default Hero;
