import { Button, Illustration, Section } from "@/components";
import { DataContext } from "@/pages";
import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import React, { useContext } from "react";

import blob from "./blob.svg";

const Compact = styled.div`
  & > * {
    margin: 0;
  }
`;

const Container = styled.div`
  background-image: url(${blob});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const OurServices = () => {
  const doc = useContext(DataContext);
  const node = doc.body[0];

  if (!node) return null;

  return (
    <Section id="our-services" classNames="uk-text-center uk-position-relative">
      <Container className="uk-container uk-container-small no-illo">
        <h2>Our Services</h2>
        <Compact className="uk-margin-auto uk-margin-large-bottom">
          <RichText render={node.primary.text} />
        </Compact>

        <div
          className="uk-width-4-5@s uk-margin-auto uk-child-width-1-2 uk-child-width-expand@s  uk-grid uk-grid-large uk-grid-collapse"
          data-uk-grid
        >
          {node.fields.map(({ name, caption, illustration }, index) => (
            <div
              key={index}
              data-uk-parallax="y: 20, -20; opacity: 0,1; viewport: 0.4"
            >
              <Compact key={index.toString()}>
                <Illustration image={illustration} />
                <RichText render={name} />
                <RichText render={caption} />
              </Compact>
            </div>
          ))}
        </div>
      </Container>
      <Button.CTA color="pink" to="/what-we-do">
        Learn More
      </Button.CTA>
      <br />
    </Section>
  );
};

export default OurServices;
