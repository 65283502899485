import { Button, Image, Section, Text } from "@/components";
import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import React from "react";

import blob from "./blob3.svg";

const Container = styled.div`
  background-image: url(${blob});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const PastWork = ({ data }) => {
  if (!data) return null;
  return (
    <Section classNames="uk-text-center uk-position-relative">
      <Container
        className="uk-container uk-container-small "
        style={{ background: "none" }}
      >
        <h2 className="uk-margin-medium-bottom">Past Work</h2>
        <div style={{ position: "margin", width: "0 auto%" }}>
          <Text pure>
            {
              data.prismic.allHomepages.edges[0].node.body[2].primary
                .past_description
            }
          </Text>
        </div>
        <div
          className="uk-child-width-expand uk-grid uk-grid-large"
          data-uk-grid
        >
          {data.prismic.allProjects.edges.map(({ node }, index) => (
            <div key={index.toString()} style={{ marginTop: "90px" }}>
              <Image {...node.thumbnail} />
              <div className="uk-text-lead uk-text-bold uk-margin">
                {RichText.asText(node.title)}
              </div>
            </div>
          ))}
        </div>
        <Button.CTA color="green" to="/portfolios">
          View All
        </Button.CTA>
        <br />
      </Container>
    </Section>
  );
};

export default PastWork;
